// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-post-page-js": () => import("./../src/components/blog-post-page.js" /* webpackChunkName: "component---src-components-blog-post-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mrs-vancouver-island-js": () => import("./../src/pages/mrs-vancouver-island.js" /* webpackChunkName: "component---src-pages-mrs-vancouver-island-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-personal-passions-js": () => import("./../src/pages/personal-passions.js" /* webpackChunkName: "component---src-pages-personal-passions-js" */),
  "component---src-pages-spoonie-living-js": () => import("./../src/pages/spoonie-living.js" /* webpackChunkName: "component---src-pages-spoonie-living-js" */)
}

